.cosaInParticolare {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
}
.parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-61xl);
}
.nelPrezzoDelWeddingPack {
  display: block;
}
.inoltreConsigliatoMettersi {
  display: block;
  color: var(--color-gray-300);
}
.nelPrezzoDelContainer {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--text-size);
  white-space: pre-wrap;
  text-align: left;
  color: var(--black);
}
.cosaInParticolareMiRappresParent,
.me {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cosaInParticolareMiRappresParent {
  gap: var(--gap-31xl);
}
.me {
  overflow: hidden;
  padding: 0 var(--padding-111xl) var(--padding-131xl);
  text-align: center;
  font-size: var(--h2-bold-size);
  color: var(--primary);
  font-family: var(--h3);
}
@media screen and (max-width: 1200px) {
  .parent {
    flex-direction: column;
  }
}
@media screen and (max-width: 960px) {
  .parent {
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 500px) {
  .cosaInParticolare {
    font-size: var(--font-size-31xl);
  }
  .nelPrezzoDelContainer {
    font-size: var(--font-size-xl);
  }
  .me {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}
