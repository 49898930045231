.navbar {
    margin: 0;
    background-color: var(--black);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-34xl) var(--padding-101xl);
    box-sizing: border-box;
    text-align: left;
    font-size: var(--link-size);
    color: var(--white);
    font-family: var(--font-poppins);
    width: 100%;
    display: flex;
  }
  @media screen and (max-width: 1200px) {
    .navbar {
      padding-left: var(--padding-41xl);
      padding-right: var(--padding-41xl);
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 960px) {
    .navbar {
      padding-left: var(--padding-21xl);
      padding-right: var(--padding-21xl);
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 500px) {
    .navbar {
      padding: var(--padding-11xl) var(--padding-xl);
      box-sizing: border-box;
    }
  }
  
  .navbar.fixed {
    background-color: black !important;
    transition: .3s ease-in-out;
    position: fixed;
    z-index: 1111111;
    padding: 45px !important;
  }