.img94221Icon {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 412px;
  object-fit: cover;
  transition: transform 0.3s;
}
.img94221Icon:hover {
  transform: scale(1.1);
}

.fareMusicaE {
  display: block;
}
.fareMusicaEContainer {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-weight: 700;
}
.contentQuote,
.textCta {
  display: flex;
  justify-content: flex-start;
}
.textCta {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-41xl) 0;
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  padding: 10px;
  background-color: #000;
  border-radius: 10px;
  display: inline-block;
}
.contentQuote {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-81xl) var(--padding-111xl);
  gap: var(--gap-xl);
}
.chiSonoQuasiFinal,
.quoteChiSono {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.quoteChiSono {
  align-self: stretch;
  background-image: url(/public/back-quote-img.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.chiSonoQuasiFinal {
  width: 100%;
  position: relative;
  background-color: var(--white);
  text-align: left;
  font-size: var(--text-size);
  color: var(--white);
  font-family: var(--h3);
}
@media screen and (max-width: 960px) {
  .img94221Icon,
  .textCta {
    flex: unset;
    align-self: stretch;
  }
  .contentQuote {
    flex-direction: column;
  }
}
@media screen and (max-width: 500px) {
  .fareMusicaEContainer {
    font-size: var(--font-size-xl);
  }
  .contentQuote {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}

.containerImg {
  overflow: hidden;
}
