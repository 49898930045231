@keyframes slide-in-left {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.chiSono,
.logo {
  cursor: pointer;
  text-decoration: none;
  width: 249px;
  position: relative;
  display: inline-block;
}
.logo {
  font-size: var(--link-size);
  letter-spacing: 1px;
  line-height: 40px;
  font-weight: 600;
  font-family: var(--font-poppins);
  color: var(--primary);
  text-align: center;
}
.chiSono {
  font-weight: 700;
  color: inherit;
}
.chiSono:hover {
  color: var(--primary);
}
.logoParent,
.navbarHidden {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.logoParent {
  flex-direction: column;
  gap: var(--gap-11xl);
}
.navbarHidden {
  width: 298px;
  background-color: var(--white);
  flex-direction: row;
  padding: var(--padding-6xl);
  box-sizing: border-box;
  opacity: 0;
  height: 100%;
  max-width: 90%;
  overflow: auto;
  text-align: left;
  font-size: var(--text-size);
  color: var(--black);
  font-family: var(--h3);
}
.navbarHidden.animate {
  animation: 0.25s ease 0s 1 normal forwards slide-in-left;
}
