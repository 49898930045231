.parolaAVoi {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--h2-size);
  font-weight: 400;
  font-family: inherit;
  color: var(--primary);
  text-align: center;
}
.child {
  flex: 1;
  position: relative;
  max-width: 100%;
  height: 384px;
  object-fit: cover;
  transition: transform 0.3s;
}
.child:hover {
  transform: scale(1.1);
}
.marcoEGiulia {
  align-self: stretch;
  position: relative;
}
.starSolid1Icon,
.starSolid2Icon,
.starSolid3Icon,
.starSolid4Icon,
.starSolid5Icon,
.starSolid6Icon {
  position: absolute;
  height: 100%;
  width: 14.04%;
  top: 0;
  right: 85.96%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.starSolid2Icon,
.starSolid3Icon,
.starSolid4Icon,
.starSolid5Icon,
.starSolid6Icon {
  right: 68.77%;
  left: 17.19%;
}
.starSolid3Icon,
.starSolid4Icon,
.starSolid5Icon,
.starSolid6Icon {
  right: 51.58%;
  left: 34.39%;
}
.starSolid4Icon,
.starSolid5Icon,
.starSolid6Icon {
  right: 34.39%;
  left: 51.58%;
}
.starSolid5Icon,
.starSolid6Icon {
  right: 17.19%;
  left: 68.77%;
}
.starSolid6Icon {
  right: 0;
  left: 85.96%;
}
.startReviews {
  width: 285px;
  position: relative;
  height: 35.9px;
}
.abbiamoContattatoLorenzo {
  margin: 0;
}
.abbiamoContattatoLorenzoContainer {
  align-self: stretch;
  position: relative;
  white-space: pre-wrap;
}
.div,
.marcoEGiuliaParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.marcoEGiuliaParent {
  flex: 1;
  flex-direction: column;
}
.div {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-3xs) 0;
}
.item {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 384px;
  object-fit: cover;
}
.reviews,
.reviewsContent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.reviewsContent {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-131xl) var(--padding-111xl);
  gap: var(--gap-57xl);
}
.reviews {
  align-self: stretch;
  background-color: var(--white);
  overflow: hidden;
  flex-direction: row;
  text-align: left;
  font-size: var(--text-size);
  color: var(--black);
  font-family: var(--h3);
}
@media screen and (max-width: 1200px) {
  .child,
  .marcoEGiuliaParent {
    flex: unset;
    align-self: stretch;
  }
  .div {
    flex-direction: column;
  }
  .item {
    flex: unset;
    align-self: stretch;
  }
}
@media screen and (max-width: 960px) {
  .child {
    display: flex;
    width: 100%;
  }
  .child,
  .marcoEGiuliaParent {
    flex: unset;
    align-self: stretch;
  }
  .div {
    flex-direction: column;
  }
  .item {
    flex: unset;
    align-self: stretch;
  }
}
@media screen and (max-width: 500px) {
  .parolaAVoi {
    font-size: var(--font-size-31xl);
  }
  .abbiamoContattatoLorenzoContainer,
  .marcoEGiulia {
    font-size: var(--font-size-xl);
  }
  .reviewsContent {
    padding-left: var(--padding-31xl) !important;
    padding-right: var(--padding-31xl) !important;
    box-sizing: border-box;
  }
}

.containerImg {
  overflow: hidden;
}
