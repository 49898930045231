.cosaStaiAspettando {
  align-self: stretch;
  position: relative;
  font-size: var(--h2-size);
  font-weight: 400;
  color: var(--primary);
}
.cosaStaiAspettando,
.mandaciLaTua,
.nonVediamoLora {
  margin: 0;
  font-family: var(--h3);
  text-align: center;
}
.mandaciLaTua {
  align-self: stretch;
  position: relative;
  font-size: var(--h3-size);
  font-weight: 400;
  color: var(--primary);
}
.nonVediamoLora {
  font-size: var(--text-size);
  color: var(--black);
}
.frameChild,
.ilTuoNome,
.nonVediamoLora {
  align-self: stretch;
  position: relative;
}
.ilTuoNome {
  margin: 0;
  font-size: var(--text-size);
  font-family: var(--h3);
  color: var(--color-gray-100);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  flex-shrink: 0;
}
.frameChild {
  border: 1px solid var(--black);
  outline: 0;
  background-color: var(--grigino);
  box-sizing: border-box;
  height: 66px;
}
.ilTuoNomeLaTuaAziendaParent {
  align-self: stretch;
  height: 124px;
  flex-direction: column;
  gap: var(--gap-lg);
}
.emailParent,
.frameParent,
.ilTuoNomeLaTuaAziendaParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.emailParent {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-lg);
}
.frameParent {
  align-self: stretch;
  height: 124px;
  flex-direction: row;
  gap: var(--gap-xl);
}
.rectangleInput {
  border: 1px solid var(--black);
  outline: 0;
  background-color: var(--grigino);
  align-self: stretch;
  position: relative;
  box-sizing: border-box;
  height: 246px;
}
.laTuaRichiestaParent,
.mandaciLaTuaRichiestaParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
}
.mandaciLaTuaRichiestaParent {
  align-items: center;
  justify-content: center;
  gap: var(--gap-16xl);
}
.contactContainerHomepage,
.contactForm {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.contactForm {
  margin: 0;
  gap: var(--gap-16xl);
}
.contactContainerHomepage {
  background-color: var(--white);
  overflow: hidden;
  padding: 0 var(--padding-111xl) var(--padding-131xl);
}

.buttonForm {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xl) var(--padding-6xl);
  background-color: var(--primary);
  border-radius: var(--br-3xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: var(--link-size);
  font-family: var(--h3);
  color: var(--white);
  text-align: center;
}

.buttonForm:hover {
  background: linear-gradient(
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.2)
  ),
  #cea350;
}
@media screen and (max-width: 500px) {
  .buttonForm {
    font-size: var(--font-size-xl);
  }
}
@media screen and (max-width: 500px) {
  .cosaStaiAspettando {
    font-size: var(--font-size-31xl);
  }
  .mandaciLaTua {
    font-size: var(--font-size-16xl);
  }
  .ilTuoNome,
  .nonVediamoLora {
    font-size: var(--font-size-xl);
  }
  .contactContainerHomepage {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}
