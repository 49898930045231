.servizi1 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}
.matrimoni {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  background-image: url(/public/matrimoni@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  transition: transform 0.3s;
}
.matrimoni:hover {
  transform: scale(1.1);
}
.aziendali,
.esimi,
.eventiAziendali,
.matrimoni {
  display: flex;
  align-items: center;
  justify-content: center;
}
.eventiAziendali {
  align-self: stretch;
  position: relative;
  height: 255px;
  flex-shrink: 0;
}
.aziendali {
  flex: 1;
  flex-direction: column;
  background-image: url(/public/aziendali@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  transition: transform 0.3s;
}
.aziendali:hover {
  transform: scale(1.1);
}

.esimi {
  background-image: url(/public/18esimi@3x.png);
  transition: transform 0.3s;
  flex: 1;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.esimi:hover {
  transform: scale(1.1);
}
.content,
.servizi,
.serviziHomepage {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.content {
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-36xl);
  font-size: var(--text-size);
  color: var(--white);
}
.servizi,
.serviziHomepage {
  background-color: var(--white);
  flex-direction: column;
}
.servizi {
  align-items: center;
  gap: var(--gap-61xl);
}
.serviziHomepage {
  overflow: hidden;
  align-items: flex-start;
  padding: var(--padding-85xl) var(--padding-111xl);
  text-align: center;
  font-size: var(--h2-size);
  color: var(--primary);
  font-family: var(--h3);
}
@media screen and (max-width: 960px) {
  .aziendali,
  .esimi,
  .matrimoni {
    flex: unset;
    align-self: stretch;
  }
  .content {
    flex-direction: column;
  }
}
@media screen and (max-width: 500px) {
  .servizi1 {
    font-size: var(--font-size-31xl);
  }
  .serviziHomepage {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}

