.frameChild {
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  height: 500px;
  object-fit: cover;
}
.frameParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 0;
}
.wrapper {
  flex: 1;
  background-color: var(--black);
  height: 500px;
}
.frameGroup {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
}
.matrimoni {
  position: relative;
}
.matrimoniWrapper {
  width: 511px;
  margin: 0 !important;
  position: absolute;
  top: calc(50% - 116px);
  left: calc(50% - 255px);
  background-color: var(--color-gray-200);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-81xl) var(--padding-181xl);
  box-sizing: border-box;
  z-index: 2;
}
.galleryMatrimoni,
.galleryMatrimoni1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.galleryMatrimoni1 {
  align-items: center;
  justify-content: center;
  position: relative;
}
.galleryMatrimoni {
  background-color: var(--white);
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-131xl) var(--padding-111xl);
  text-align: center;
  font-size: var(--text-size);
  color: var(--white);
  font-family: var(--h3);
}
.title{
  color: #cea350;
}
@media screen and (max-width: 1200px) {
  .frameChild {
    flex: unset;
    align-self: stretch;
  }
  .frameParent {
    flex-direction: column;
  }
  .wrapper {
    flex: unset;
    align-self: stretch;
  }
  .frameGroup {
    flex-direction: column;
  }
}
@media screen and (max-width: 500px) {
  .matrimoni {
    font-size: var(--font-size-xl);
  }
  .galleryMatrimoni {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}
