.button {
  position: relative;
}
.property1variant2 {
  border-radius: var(--br-3xs);
  border: 4px solid var(--primary);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xl) var(--padding-6xl);
  text-align: center;
  font-size: var(--link-size);
  color: var(--white);
  font-family: var(--h3);
}
.property1variant2:hover {
  background: linear-gradient(
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #cea350;
}
@media screen and (max-width: 500px) {
  .button {
    font-size: var(--font-size-xl);
  }
  .property1variant2 {
    padding: 20px;

  }
}
