.banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #cea350;
  color: #fff;
  text-align: center;
  transition: transform 0.3s linear;
  display: none;
  z-index: 111111111;
}

.banner-text {
  width: 50%;
  float: left;
  font-size: var(--text-size);
  font-family: var(--h3);
}

.cta {
  width: 50%;
  float: left;
  border-radius: var(--br-3xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--white);
  font-family: var(--h3);
  background-color: black;
  margin-right: 20px;
}

@media (max-width: 768px) {
  .banner {
    transform: translateY(100%);
    display: flex;
    align-items: center;
  }

  .banner.show-cta {
    transform: translateY(0);
  }
}