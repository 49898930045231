.carousel-root {
  display: none;
  width: 100%;
}
.container-carousel {
  border: 1px solid black;
  text-align: center;
  position: relative;
}

.lefollie-text {
  color: var(--black);
  font-family: var(--font-poiret-one);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: var(--h2-size);
}

.carousel .control-dots .dot {
  background: #cea350 !important;
}

@media screen and (max-width: 960px) {
  .carousel-root{
    display: block;
  }
}
