.frameChild {
  width: 363px;
  position: relative;
  background-color: var(--primary);
  height: 2px;
}
.gallery,
.quiPuoiTrovare {
  margin: 0;
  align-self: stretch;
  position: relative;
}
.gallery {
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}
.quiPuoiTrovare {
  font-size: var(--subtitle-size);
}
.contentHero,
.heroGalllery,
.rectangleParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.rectangleParent {
  align-items: center;
  justify-content: center;
  gap: var(--gap-31xl);
}
.contentHero,
.heroGalllery {
  justify-content: flex-start;
}
.contentHero {
  align-items: center;
  gap: var(--gap-sm);
}
.heroGalllery {
  background-color: var(--color-gray-200);
  height: 600px;
  overflow: hidden;
  flex-shrink: 0;
  align-items: flex-start;
  text-align: center;
  font-size: var(--h1-size);
  color: var(--white);
  font-family: var(--h3);
}
@media screen and (max-width: 500px) {
  .gallery {
    font-size: var(--font-size-51xl);
  }
  .quiPuoiTrovare {
    font-size: var(--font-size-xl);
  }
  .rectangleParent {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
  .contentHero,
  .heroGalllery {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
    height: 500px;
  }
}
