.iMieiServizi {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}
.servizi,
.serviziWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.servizi {
  gap: var(--gap-81xl);
}
.serviziWrapper {
  overflow: hidden;
  padding: var(--padding-131xl) var(--padding-111xl);
  text-align: center;
  font-size: var(--h2-size);
  color: var(--primary);
  font-family: var(--h3);
}
@media screen and (max-width: 500px) {
  .iMieiServizi {
    font-size: var(--font-size-31xl);
  }
  .serviziWrapper {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}
