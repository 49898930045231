/* formGoogleCalendar.module.css */

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 150px; /* Margine laterale di 150px */
    box-sizing: border-box; /* Assicura che il padding non aumenti la larghezza totale */
    width: 100%;
    padding: 150px 150px 0px 150px;
}

.form {
    width: 50%;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'PT Sans', sans-serif; /* Applica la famiglia di font a tutto il form */
    gap: var(--gap-16xl);
}
.comePossiamoAiutarti {
    margin: 0;
  }
  .comePossiamoAiutartiContainer {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-size: var(--h3-size);
    font-weight: 700;
    font-family: inherit;
    color: var(--primary);
    display: inline-block;
    flex-shrink: 0;
  }
  .mandaciLaTua {
    display: block;
  }
  .mandaciLaTuaContainer {
    display: inline-block;
    height: 128px;
    flex-shrink: 0;
  }
  .mandaciLaTuaContainer,
  .oraToccaAContainer {
    margin: 0;
    align-self: stretch;
    position: relative;
  }
  .comePossiamoAiutartiParent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gap-11xl);
    padding: 30px;
  }

.label {
    margin: 0;
    font-size: var(--text-size);
    font-family: var(--h3);
    color: var(--color-gray-100);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    flex-shrink: 0;
}

.input, .textarea {
    border: 1px solid var(--black);
    outline: 0;
    background-color: var(--grigino);
    box-sizing: border-box;
    height: 66px;
    width: 100%;
}

.textarea {
    height: 100px; /* Aumenta l'altezza del campo descrizione */
}

.button {
    cursor: pointer;
    border: 0;
    padding: var(--padding-xl) var(--padding-6xl);
    background-color: var(--primary);
    border-radius: var(--br-3xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: var(--link-size);
    font-family: var(--h3);
    color: var(--white);
    text-align: center;
}

.button:hover {
    background-color: #b58c3f; /* Cambia il colore del bottone al passaggio del mouse */
}

.message {
    margin-top: 16px;
    padding: 10px;
    border-radius: 4px;
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
    text-align: center;
    font-family: 'PT Sans', sans-serif; /* Applica la famiglia di font al messaggio */
}

.rowVertical{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.row {
    width: 100%;
    display: flex;
    gap: var(--gap-xl);
}
.row .box {
    width: 50%;
}


/* Media query per dispositivi con schermi più piccoli */
@media (max-width: 768px) {
    .container {
        padding-left: var(--padding-31xl);
        padding-right: var(--padding-31xl);
        flex-direction: column;
        padding-top: 100px;
    

    }

    .row {
        flex-direction: column; /* Imposta i campi "Inizio" e "Fine" in colonna su schermi più piccoli */
    }

    .row .input {
        width: 100%; /* Imposta la larghezza al 100% su schermi più piccoli */
    }
    .row .box {
        width: 100%;
    }
    .form {
        width: 100%;
    }

    .comePossiamoAiutartiParent{
        width: 100%;
    }

    .button {
        font-size: var(--font-size-xl);
    }
}
