.lorenzoGoretti {
  width: 311px;
  position: relative;
  letter-spacing: 0.03em;
  font-weight: 900;
  display: inline-block;
  height: 39px;
  flex-shrink: 0;
}
.serviziComeDj {
  color: var(--primary);
}
.miChiamoLorenzoContainer {
  width: 311.3px;
  position: relative;
  font-size: var(--text-size);
  font-family: var(--h3);
  display: inline-block;
}
.groupChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 11.25px;
  background-color: var(--primary);
}
.vectorIcon {
  position: absolute;
  height: 48.21%;
  width: 25.64%;
  top: 25.13%;
  right: 37.69%;
  bottom: 26.67%;
  left: 36.67%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.rectangleParent {
  width: 39px;
  position: relative;
  height: 39px;
}
.vectorIcon1 {
  position: absolute;
  height: 42.05%;
  width: 42.05%;
  top: 28.97%;
  right: 28.97%;
  bottom: 28.97%;
  left: 28.97%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.in,
.vectorIcon2 {
  position: absolute;
  left: 30.77%;
}
.vectorIcon2 {
  height: 38.97%;
  top: 30.77%;
  right: 21.03%;
  bottom: 30.26%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.in {
  height: 53.85%;
  width: 44.36%;
  top: 11.54%;
  letter-spacing: 0.02em;
  line-height: 30px;
  display: inline-block;
}
.fashion,
.logos {
  display: flex;
  align-items: flex-start;
}
.logos {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-2xs-5);
  font-size: var(--font-size-lg);
  color: var(--black);
  font-family: var(--font-roboto);
}
.fashion {
  flex-direction: column;
  justify-content: center;
  gap: var(--gap-5xl);
}
.esimi,
.matrimoni,
.servizi {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  color: inherit;
  display: inline-block;
  height: 21px;
  flex-shrink: 0;
}
.servizi {
  width: 79px;
  font-weight: 700;
}
.esimi,
.matrimoni {
  width: 124px;
}
.esimi {
  width: 103px;
}
.column1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6xl);
}
.linkUtili {
  width: 132px;
  position: relative;
  font-weight: 700;
  display: inline-block;
  height: 21px;
  flex-shrink: 0;
}
.chiSono,
.gallery {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  height: 21px;
  flex-shrink: 0;
}
.chiSono {
  width: 125px;
  position: relative;
  display: inline-block;
}
.gallery {
  width: 132px;
}
.contatti,
.div,
.gallery,
.gorettilollogmailcom {
  position: relative;
  display: inline-block;
}
.contatti {
  width: 161px;
  color: var(--white);
  height: 21px;
  flex-shrink: 0;
}
.div,
.gorettilollogmailcom {
  width: 190px;
}
.gorettilollogmailcom {
  width: 268px;
  height: 21px;
  flex-shrink: 0;
}
.column3 {
  width: 268px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  color: var(--primary);
}
.footer,
.innerfooter,
.links {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.links {
  justify-content: flex-start;
  gap: 141.02px;
  font-size: var(--text-size);
  font-family: var(--h3);
}
.footer,
.innerfooter {
  align-self: stretch;
  justify-content: space-between;
}
.footer {
  background-color: var(--color-gray-200);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-81xl) var(--padding-111xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--link-size);
  color: var(--white);
  font-family: var(--font-poppins);
}
@media screen and (max-width: 1200px) {
  .innerfooter {
    flex-direction: column;
    gap: var(--gap-31xl);
  }
}
@media screen and (max-width: 960px) {
  .links {
    flex-direction: column;
    gap: var(--gap-31xl);
  }
}
@media screen and (max-width: 500px) {
  .chiSono,
  .contatti,
  .div,
  .esimi,
  .gallery,
  .gorettilollogmailcom,
  .linkUtili,
  .matrimoni,
  .miChiamoLorenzoContainer,
  .servizi {
    font-size: var(--font-size-xl);
  }
  .footer {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}
