.ilMioRepertorio {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}

.containerImg{
  overflow: hidden;
}
.contentRepertorioChild {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 486px;
  object-fit: cover;
  transition: transform 0.3s;
}
.contentRepertorioChild:hover {
  transform: scale(1.1);
} 
.grazieAiMie {
  display: block;
}
.rockMetal {
  margin-bottom: 0;
}
.rockMetalPunkDiscoAnn {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-14xl);
}
.grazieAiMieContainer {
  margin: 0;
  flex: 1;
  position: relative;
}
.contentRepertorio {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xl);
  text-align: left;
  font-size: var(--text-size);
  color: var(--black);
}
.contentRep,
.repertorio {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.contentRep {
  align-items: center;
  padding: var(--padding-131xl) 0 0;
  gap: var(--gap-81xl);
}
.repertorio {
  overflow: hidden;
  align-items: flex-start;
  padding: 0 var(--padding-111xl);
  text-align: center;
  font-size: var(--h2-size);
  color: var(--primary);
  font-family: var(--h3);
}
@media screen and (max-width: 960px) {
  .contentRepertorioChild,
  .grazieAiMieContainer {
    flex: unset;
    align-self: stretch;
  }
  .contentRepertorio {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}
@media screen and (max-width: 500px) {
  .ilMioRepertorio {
    font-size: var(--font-size-31xl);
  }
  .grazieAiMieContainer {
    font-size: var(--font-size-xl);
  }
  .repertorio {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}
