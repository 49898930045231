.aziendeConCui,
.leFollie1 {
  align-self: stretch;
  position: relative;
}
.aziendeConCui {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}
.leFollie1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 203px;
  flex-shrink: 0;
  width: 100%;
}
.leFollie,
.leFollie2,
.leFollie6 {
  flex: 1;
  border: 1px solid var(--black);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
.leFollie8 {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 203px;
}
.azienda5,
.aziendeChiSono,
.partners {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.azienda5 {
  flex: 1;
  border: 1px solid var(--black);
  align-items: flex-end;
}
.aziendeChiSono,
.partners {
  align-self: stretch;
  align-items: center;
  gap: var(--gap-xl);
  text-align: center;
  color: var(--black);
  font-family: var(--font-poiret-one);
}
.aziendeChiSono {
  background-color: var(--white);
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-131xl) var(--padding-111xl);
  gap: var(--gap-33xl);
  text-align: left;
  font-size: var(--h2-size);
  color: var(--primary);
  font-family: var(--h3);
}
@media screen and (max-width: 1200px) {
  .azienda5,
  .leFollie6 {
    display: none;
  }
  .aziendeChiSono {
    display: flex;
  }
}
@media screen and (max-width: 960px) {
  .leFollie {
    flex: unset;
    align-self: stretch;
  }
  .leFollie2 {
    display: none;
  }
  .azienda5,
  .leFollie2,
  .leFollie6 {
    flex: unset;
    align-self: stretch;
  }
  .partners {
    flex-direction: column;
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .aziendeConCui {
    font-size: var(--font-size-31xl);
  }
  .aziendeChiSono {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}


