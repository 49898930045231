.child,
.item {
  position: absolute;
}
.child {
  top: 56px;
  left: calc(50% - 182px);
  border-radius: var(--br-32xl);
  background-color: var(--primary);
  width: 364px;
  height: 360px;
}
.item {
  top: 0;
  left: calc(50% - 37.6px);
  width: 75.2px;
  height: 76px;
}
.dispongoDiTutti {
  display: block;
}
.dispongoDiTuttiContainer1 {
  width: 100%;
}
.dispongoDiTuttiContainer {
  margin: 0;
  position: absolute;
  top: 89px;
  left: calc(50% - 149.9px);
  display: flex;
  align-items: center;
  width: 296.8px;
  height: 294px;
}
.div {
  flex: 1;
  position: relative;
  height: 416px;
  text-align: center;
  font-size: var(--text-size);
  color: var(--white);
  font-family: var(--h3);
}
@media screen and (max-width: 1200px) {
  .div {
    flex: unset;
    align-self: stretch;
  }
}
@media screen and (max-width: 500px) {
  .dispongoDiTuttiContainer {
    font-size: var(--font-size-xl);
  }
}
