.laMiaAttrezzatura {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
}
.frameChild,
.frameItem {
  max-width: 50%;
  overflow: hidden;
  object-fit: cover;
}
.frameChild {
  align-self: stretch;
  max-height: 100%;
}
.frameItem {
  flex: 1;
  position: relative;
}
.rectangleParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xl);
}
.attrezzatura,
.laMiaAttrezzaturaParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.laMiaAttrezzaturaParent {
  gap: var(--gap-81xl);
}
.attrezzatura {
  overflow: hidden;
  padding: var(--padding-125xl) var(--padding-111xl);
  text-align: center;
  font-size: var(--h2-bold-size);
  color: var(--primary);
  font-family: var(--h3);
}
@media screen and (max-width: 1200px) {
  .frameItem, .frameChild {
    flex: unset;
    width: 80%;
  }
  .rectangleParent {
    flex-direction: column;
    padding: var(--padding-21xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 500px) {
  .laMiaAttrezzatura {
    font-size: var(--font-size-31xl);
  }
  .attrezzatura {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}
