.vuoiSaperneDi {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
}
.serviziQuasiFinalInner,
.vuoiSaperneDiPiParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.vuoiSaperneDiPiParent {
  align-items: center;
  gap: var(--gap-31xl);
}
.serviziQuasiFinalInner {
  align-items: flex-start;
  padding: 171px 0;
  background-image: url(/public/frame-87@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.serviziQuasiFinal {
  width: 100%;
  position: relative;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--h3-size);
  color: var(--primary);
  font-family: var(--h3);
}
@media screen and (max-width: 500px) {
  .vuoiSaperneDi {
    font-size: var(--font-size-16xl);
  }
  .vuoiSaperneDiPiParent {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}
