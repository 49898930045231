.prossimeDate {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--h2-bold-size);
  font-weight: 700;
  font-family: inherit;
  color: var(--primary);
  text-align: center;
}
.p {
  opacity: 0.34;
  mix-blend-mode: normal;
}
.p,
.title {
  margin: 0;
  position: relative;
  font-weight: 700;
}
.titles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.action,
.category {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.actionEvents,
.categoryEvents {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.action {
  flex: 1;
  justify-content: space-between;
  padding: 0 0 0 var(--padding-243xl);
}
.actionEvents {
  flex: 1;
  justify-content: space-between;
  padding: 0 0 0 var(--padding-243xl);
}
.category {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-3xl);
}
.categoryEvents {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-3xl);
}
.separatorIcon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 1px;
  flex-shrink: 0;
  opacity: 0.4;
  mix-blend-mode: normal;
}
.categoriescolumn,
.categoriessection {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.categoriescolumn {
  gap: var(--gap-31xl);
}
.categoriessection {
  padding: var(--padding-131xl) var(--padding-111xl) 0;
}
.getInTouch1 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--h2-bold-size);
  font-weight: 700;
  font-family: inherit;
  color: var(--primary);
}
.homeUndefinedGlyphUnde {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  overflow: hidden;
}
.viaDeiTigli {
  margin: 0;
  position: absolute;
  top: 5px;
  left: 40px;
  font-weight: 700;
  display: inline-block;
  width: 338px;
  height: 30px;
}
.homeUndefinedGlyphUndeParent {
  width: 378px;
  position: relative;
  height: 40px;
}
.p4 {
  margin: 0;
  position: absolute;
  top: 4.5px;
  left: 40px;
  font-weight: 700;
  display: inline-block;
  width: 157px;
  height: 31px;
}
.phoneUndefinedGlyphUndParent {
  width: 212px;
  position: relative;
  height: 40px;
}
.mailUndefinedGlyphUnde {
  position: absolute;
  top: 1px;
  left: 0;
  width: 40px;
  height: 40px;
  overflow: hidden;
}
.gorettilollogmailcom {
  margin: 0;
  position: absolute;
  top: 0;
  left: 40px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 288px;
  height: 42px;
}
.mailUndefinedGlyphUndeParent {
  width: 328px;
  position: relative;
  height: 40px;
}
.frameParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-131xl);
}
.comePossiamoAiutarti {
  margin: 0;
}
.comePossiamoAiutartiContainer {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--h3-size);
  font-weight: 700;
  font-family: inherit;
  color: var(--primary);
  display: inline-block;
  height: 123px;
  flex-shrink: 0;
}
.mandaciLaTua {
  display: block;
}
.mandaciLaTuaContainer {
  display: inline-block;
  height: 128px;
  flex-shrink: 0;
}
.mandaciLaTuaContainer,
.oraToccaAContainer {
  margin: 0;
  align-self: stretch;
  position: relative;
}
.comePossiamoAiutartiParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-11xl);
}
.frameChild,
.ilTuoNome {
  align-self: stretch;
  position: relative;
}
.ilTuoNome {
  margin: 0;
  font-size: var(--text-size);
  font-family: var(--h3);
  color: var(--color-gray-100);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  flex-shrink: 0;
}
.frameChild {
  border: 1px solid var(--black);
  outline: 0;
  background-color: var(--grigino);
  box-sizing: border-box;
  height: 66px;
}
.ilTuoNomeLaTuaAziendaParent {
  align-self: stretch;
  height: 124px;
  flex-direction: column;
  gap: var(--gap-lg);
}
.emailParent,
.frameContainer,
.ilTuoNomeLaTuaAziendaParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.emailParent {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-lg);
}
.frameContainer {
  align-self: stretch;
  height: 124px;
  flex-direction: row;
  gap: var(--gap-xl);
}
.rectangleInput {
  border: 1px solid var(--black);
  outline: 0;
  background-color: var(--grigino);
  align-self: stretch;
  position: relative;
  box-sizing: border-box;
  height: 246px;
}
.frameGroup,
.laTuaRichiestaParent {
  align-self: stretch;
  flex-direction: column;
}
.laTuaRichiestaParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
}
.frameGroup {
  gap: var(--gap-16xl);
}
.contactForm,
.form,
.frameGroup,
.getInTouch {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contactForm {
  margin: 0;
  flex: 1;
  flex-direction: column;
}
.form,
.getInTouch {
  align-self: stretch;
  flex-direction: row;
  gap: 149px;
  text-align: left;
}
.getInTouch {
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-131xl) var(--padding-111xl);
  gap: 95px;
  text-align: center;
}
.title4 {
  margin: 0;
  width: 262px;
  position: relative;
  font-weight: 700;
  display: inline-block;
}
.path3Icon,
.pathIcon {
  position: absolute;
  height: 91.32%;
  width: 7.14%;
  top: 39.26%;
  right: 92.86%;
  bottom: -30.58%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  transform: rotate(90deg);
}
.pathIcon {
  height: 80.99%;
  width: 100%;
  top: 0;
  right: -23.47%;
  bottom: 19.01%;
  left: 23.47%;
  object-fit: contain;
}
.arrow,
.title9 {
  position: relative;
}
.arrow {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 19.6px;
  height: 24.2px;
  transform: rotate(-90deg);
}
.title9 {
  margin: 0;
  font-weight: 700;
  white-space: pre-wrap;
}
.bloccoFaq,
.contattiQuasiFinal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.bloccoFaq {
  align-self: stretch;
  padding: 0 var(--padding-111xl) var(--padding-131xl);
}
.contattiQuasiFinal {
  width: 100%;
  position: relative;
  background-color: var(--white);
  gap: 1px;
  text-align: left;
  font-size: var(--text-size);
  color: var(--black);
  font-family: var(--h3);
}
@media screen and (max-width: 1200px) {
  .action,
  .categoriessection {
    padding-left: var(--padding-61xl);
    box-sizing: border-box;
  }
  .actionEvents {
    padding-left: var(--padding-61xl);
    box-sizing: border-box;
  }
  .categoriessection {
    padding-right: var(--padding-61xl);
  }
  .frameParent {
    flex-direction: column;
    gap: 50px;
  }
  .comePossiamoAiutartiParent,
  .contactForm {
    flex: unset;
    align-self: stretch;
  }
  .form {
    flex-direction: column;
  }
  .bloccoFaq {
    padding-left: var(--padding-61xl);
    padding-right: var(--padding-61xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 960px) {
  .title {
    font-size: var(--font-size-9xl);
    line-height: 35px;
  }
  .action,
  .categoriessection {
    padding-left: var(--padding-xl);
    box-sizing: border-box;
  }
  .actionEvents {
    padding-left: var(--padding-xl);
    box-sizing: border-box;
  }
  .categoriessection {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
  }
  .frameParent {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
  }
  .title4,
  .title9 {
    font-size: var(--font-size-9xl);
    line-height: 35px;
  }
  .bloccoFaq {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
  
}
@media screen and (max-width: 500px) {
  .prossimeDate {
    font-size: var(--font-size-31xl);
  }
  .p,
  .title {
    font-size: var(--font-size-xl);
  }
  .action {
    padding-left: 0;
    box-sizing: border-box;
  }
  .actionEvents{
    padding-left: 0;
    box-sizing: border-box;
  }
  .categoriessection {
    padding: var(--padding-81xl) var(--padding-11xl) var(--padding-21xl);
    box-sizing: border-box;
  }
  .getInTouch1 {
    font-size: var(--font-size-31xl);
  }
  .gorettilollogmailcom,
  .p4,
  .viaDeiTigli {
    font-size: var(--font-size-xl);
  }
  .frameParent {
    gap: var(--gap-11xl);
    padding-top: 0;
    box-sizing: border-box;
  }
  .comePossiamoAiutartiContainer {
    font-size: var(--font-size-16xl);
  }
  .ilTuoNome,
  .mandaciLaTuaContainer,
  .oraToccaAContainer {
    font-size: var(--font-size-xl);
  }
  .getInTouch {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    padding-top: var(--padding-31xl) ;
    box-sizing: border-box;
  }
  .title4,
  .title9 {
    font-size: var(--font-size-xl);
  }
  .bloccoFaq {
    padding: var(--padding-21xl) var(--padding-31xl) var(--padding-131xl);
    box-sizing: border-box;
  }
}

.buttonForm {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xl) var(--padding-6xl);
  background-color: var(--primary);
  border-radius: var(--br-3xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: var(--link-size);
  font-family: var(--h3);
  color: var(--white);
  text-align: center;
}

.buttonForm:hover {
  background: linear-gradient(
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.2)
  ),
  #cea350;
}
@media screen and (max-width: 500px) {
  .buttonForm {
    font-size: var(--font-size-xl);
  }
  .category {
    flex-direction: row;
  }
  .action{
    flex-direction: row;
  }
  .categoryEvents {
    flex-direction: column;
  }
  .actionEvents{
    flex-direction: column;
  }
}

@media screen and (max-width: 700px) {
  .categoryEvents {
    flex-direction: column;
  }
  .actionEvents{
    flex-direction: column;
  }
}
