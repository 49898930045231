.iMieiValori1,
.soddisfazioneDelCliente {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
}
.iMieiValori1 {
  align-self: stretch;
  position: relative;
  font-weight: 700;
}
.soddisfazioneDelCliente {
  padding-left: var(--padding-14xl);
}
.soddisfazioneDelClienteContainer {
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.ricercaMusicaleDettagliataContainer,
.soddisfazioneDelClienteContainer {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-weight: 700;
}
.soddisfazioneDelClienteParent {
  gap: var(--gap-xl);
  font-size: var(--text-size);
  color: var(--white);
}
.iMieiValori,
.iMieiValoriWrapper,
.soddisfazioneDelClienteParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.iMieiValori {
  gap: var(--gap-81xl);
}
.iMieiValoriWrapper {
  overflow: hidden;
  padding: var(--padding-55xl) var(--padding-111xl);
  background-image: url(/public/background-values.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: var(--h2-bold-size);
  color: var(--primary);
  font-family: var(--h3);
}
.nopunti {
  list-style: none;
}
@media screen and (max-width: 960px) {
  .soddisfazioneDelClienteParent {
    flex-direction: column;
  }
}
@media screen and (max-width: 500px) {
  .iMieiValori1 {
    font-size: var(--font-size-31xl);
  }
  .ricercaMusicaleDettagliataContainer,
  .soddisfazioneDelClienteContainer {
    font-size: var(--font-size-xl);
  }
  .iMieiValoriWrapper {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}
