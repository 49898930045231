.iMetodiDi {
  flex: 1;
  position: relative;
}
.iMetodiDiPagamentoAccetatiWrapper,
.rispostaPagamenti {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.iMetodiDiPagamentoAccetatiWrapper {
  flex: 1;
  padding: var(--padding-11xl) 0;
}
.rispostaPagamenti {
  width: 574px;
  background-color: var(--primary);
  padding: var(--padding-11xl);
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
  font-size: var(--text-size);
  color: var(--white);
  font-family: var(--h3);
}
@media screen and (max-width: 420px) {
  .iMetodiDi {
    font-size: var(--font-size-xl);
  }
  .rispostaPagamenti {
    padding: var(--padding-3xs);
    box-sizing: border-box;
  }
}
