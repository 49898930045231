.richiediUnPreventivo {
  align-self: stretch;
  position: relative;
}
.richiediSubitoUn {
  margin: 0;
}
.richiediSubitoUnContainer {
  align-self: stretch;
  position: relative;
  font-size: var(--text-size);
  color: var(--white);
}
.prefooter {
  background-color: var(--color-gray-200);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-31xl) 0;
  box-sizing: border-box;
  gap: var(--gap-26xl);
  text-align: center;
  font-size: var(--h2-bold-size);
  color: var(--primary);
  font-family: var(--h3);
  align-self: stretch;
}
@media screen and (max-width: 500px) {
  .richiediUnPreventivo {
    font-size: var(--font-size-31xl);
  }
  .richiediSubitoUnContainer {
    font-size: var(--font-size-xl);
  }
  .prefooter {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}
