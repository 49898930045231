.ilMigliorDj,
.lorenzoGoretti {
  margin: 0;
  align-self: stretch;
  position: relative;
}

.lorenzoGoretti {
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}

.ilMigliorDj {
  font-size: var(--subtitle-size);
}

.buttonParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-31xl);
}

.contentHero,
.heroHomepage {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

.contentHero {
  align-items: center;
  justify-content: center;
  padding: var(--padding-181xl) 0;
  gap: var(--gap-sm);
}

.heroHomepage {
  height: 1030px;
  overflow: hidden;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  background-image: url(/public/idea-hero-slu-crema-2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  font-size: var(--h1-size);
  color: var(--white);
  font-family: var(--h3);
}
.btnMobile {
  border-radius: var(--br-3xs);
  border: 4px solid var(--primary);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xl) var(--padding-6xl);
  text-align: center;
  font-size: var(--link-size);
  color: var(--white);
  font-family: var(--h3);
  background: none;
}
.btnMobile:hover{
  background: #cea350;
}

@media screen and (max-width: 768px) {
  .btnMobile{
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .lorenzoGoretti {
    font-family: var(--h3);
    font-size: var(--font-size-51xl);
  }

  .ilMigliorDj {
    font-size: var(--font-size-xl);
  }

  .buttonParent {
    gap: var(--gap-31xl);
  }

  .heroHomepage {
    height: auto;
    text-align: left;
  }

  .contentHero {
    box-sizing: border-box;
    padding: var(--padding-11xl) var(--padding-xl);
    text-align: center;
  }

}