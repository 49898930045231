@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poiret+One:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --h3: "PT Sans";
  --font-roboto: Roboto;
  --font-poppins: Poppins;
  --font-poiret-one: "Poiret One";

  /* font sizes */
  --text-size: 25px;
  --font-size-xl: 20px;
  --font-size-lg: 18px;
  --link-size: 30px;
  --h2-bold-size: 64px;
  --font-size-31xl: 40px;
  --h3-size: 48px;
  --font-size-16xl: 35px;
  --h2-size: 60px;
  --subtitle-size: 32px;
  --h1-size: 96px;
  --font-size-51xl: 70px;
  --font-size-9xl: 28px;

  /* Colors */
  --color-gray-100: #808080;
  --color-gray-200: #222;
  --color-gray-300: #1e1e1e;
  --primary: #cea350;
  --color-peru-100: rgba(206, 163, 80, 0);
  --white: #fff;
  --black: #000;
  --grigino: #f7f7f7;

  /* Gaps */
  --gap-31xl: 50px;
  --gap-122xl: 141px;
  --gap-5xl: 24px;
  --gap-6xl: 25px;
  --gap-2xs-5: 10.5px;
  --gap-26xl: 45px;
  --gap-16xl: 35px;
  --gap-lg: 18px;
  --gap-xl: 20px;
  --gap-33xl: 52px;
  --gap-57xl: 76px;
  --gap-61xl: 80px;
  --gap-36xl: 55px;
  --gap-3xs: 10px;
  --gap-sm: 14px;
  --gap-3xl: 22px;
  --gap-11xl: 30px;
  --gap-131xl: 150px;
  --gap-2xl: 21px;
  --gap-81xl: 100px;

  /* Paddings */
  --padding-70xl: 89px;
  --padding-56xl: 75px;
  --padding-91xl: 110px;
  --padding-31xl: 50px;
  --padding-xl: 20px;
  --padding-6xl: 25px;
  --padding-111xl: 130px;
  --padding-131xl: 150px;
  --padding-3xs: 10px;
  --padding-81xl: 100px;
  --padding-41xl: 60px;
  --padding-85xl: 104px;
  --padding-181xl: 200px;
  --padding-82xl: 101px;
  --padding-34xl: 53px;
  --padding-101xl: 120px;
  --padding-21xl: 40px;
  --padding-11xl: 30px;
  --padding-61xl: 80px;
  --padding-243xl: 262px;
  --padding-156xl: 175px;
  --padding-65xl: 84px;
  --padding-55xl: 74px;
  --padding-14xl: 33px;
  --padding-125xl: 144px;

  /* Border radiuses */
  --br-xs-3: 11.3px;
  --br-3xs: 10px;
  --br-32xl: 51px;
}
