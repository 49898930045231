.cosaInParticolare {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
}
.child,
.item {
  position: absolute;
}
.child {
  top: 56px;
  left: calc(50% - 182px);
  border-radius: var(--br-32xl);
  background-color: var(--primary);
  width: 364px;
  height: 360px;
}
.item {
  top: 0;
  left: calc(50% - 37.6px);
  width: 75.2px;
  height: 76px;
}
.dispongoDiTutti {
  display: block;
}
.dispongoDiTuttiContainer1 {
  width: 100%;
}
.dispongoDiTuttiContainer {
  margin: 0;
  position: absolute;
  top: 89px;
  left: calc(50% - 149.9px);
  display: flex;
  align-items: center;
  width: 296.8px;
  height: 294px;
}
.div {
  flex: 1;
  position: relative;
  height: 416px;
}
.inner {
  position: absolute;
  top: 57px;
  left: calc(50% - 182px);
  border-radius: var(--br-32xl);
  background-color: var(--primary);
  width: 364px;
  height: 360px;
}
.possibilitDiEffettuare {
  margin: 0;
  position: absolute;
  top: 90px;
  left: calc(50% - 149.9px);
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 296.8px;
  height: 294px;
}
.div1 {
  flex: 1;
  position: relative;
  height: 417px;
}
.flessibilitSuiGeneri,
.parent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flessibilitSuiGeneri {
  margin: 0;
  position: absolute;
  top: 90px;
  left: calc(50% - 149.9px);
  width: 296.8px;
  height: 294px;
}
.parent {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-61xl);
  font-size: var(--text-size);
  color: var(--white);
}
.cosaInParticolareMiRappresParent,
.me {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.cosaInParticolareMiRappresParent {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-31xl);
}
.me {
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-111xl) var(--padding-131xl);
  text-align: center;
  font-size: var(--h2-bold-size);
  color: var(--primary);
  font-family: var(--h3);
}
@media screen and (max-width: 1200px) {
  .div,
  .div1 {
    flex: unset;
    align-self: stretch;
  }
  .parent {
    flex-direction: column;
  }
}
@media screen and (max-width: 960px) {
  .parent {
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 500px) {
  .cosaInParticolare {
    font-size: var(--font-size-31xl);
  }
  .dispongoDiTuttiContainer,
  .flessibilitSuiGeneri,
  .possibilitDiEffettuare {
    font-size: var(--font-size-xl);
  }
  .me {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}
