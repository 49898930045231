.chiSono,
.logo,
.servizi {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  color: inherit;
}
.logo {
  letter-spacing: 1px;
  line-height: 22px;
  font-weight: 600;
}
.chiSono,
.servizi {
  width: 114px;
  display: flex;
  align-items: center;
  height: 40px;
  flex-shrink: 0;
}
.chiSono:hover,
.gallery:hover,
.servizi:hover {
  color: #cea350 !important;
}
.servizi {
  width: 88px;
}
.vectorIcon {
  position: absolute;
  height: 36.25%;
  width: 66.67%;
  top: 34.58%;
  right: 16.67%;
  bottom: 29.17%;
  left: 16.67%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.chevronDownUndefinedGly {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.serviziParent {
  flex-direction: row;
  justify-content: center;
}
.gallery,
.menulinks,
.serviziParent {
  display: flex;
  align-items: center;
}
.gallery {
  cursor: pointer;
  text-decoration: none;
  width: 94px;
  position: relative;
  color: inherit;
  height: 40px;
  flex-shrink: 0;
}
.menulinks {
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-31xl);
}
.group2Icon {
  position: absolute;
  height: 47%;
  width: 63.5%;
  top: 26.5%;
  right: 18.25%;
  bottom: 26.5%;
  left: 18.25%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.hamburgerIcon {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 40px;
  position: relative;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.menuright,
.nav,
.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.menuright {
  justify-content: center;
  gap: var(--gap-3xs);
  font-family: var(--h3);
}
.nav,
.navbar {
  align-self: stretch;
  justify-content: space-between;
}
.navbar {
  margin: 0;
  background-color: var(--black);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-34xl) var(--padding-101xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--link-size);
  color: var(--white);
  font-family: var(--font-poppins);
  align-self: stretch;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media screen and (max-width: 1200px) {
  .navbar {
    padding-left: var(--padding-41xl);
    padding-right: var(--padding-41xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 960px) {
  .menulinks {
    display: none;
  }
  .hamburgerIcon {
    display: flex;
  }
  .navbar {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 500px) {
  .navbar {
    padding: var(--padding-11xl) var(--padding-xl);
    box-sizing: border-box;
  }
}

.navbar.fixed {
  background-color: #cea350;
  transition: .3s ease-in-out;
  position: fixed;
}
