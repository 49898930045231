.graziePerLa {
  margin: 0;
}
.graziePerLaContainer {
  align-self: stretch;
  position: relative;
}
.graziePerLaTuaRichiestaParent,
.thankYouPageQuasiFinalInner {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.graziePerLaTuaRichiestaParent {
  padding: var(--padding-21xl);
  gap: var(--gap-11xl);
}
.thankYouPageQuasiFinalInner {
  height: 600px;
  overflow: hidden;
  flex-shrink: 0;
  background-image: url(/public/frame-89@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.thankYouPageQuasiFinal {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--text-size);
  color: var(--white);
  font-family: var(--h3);
}
@media screen and (mx-width: 500px) {
  .graziePerLaContainer {
    font-size: var(--font-size-xl);
  }
  .graziePerLaTuaRichiestaParent {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}
