.matrimoni {
  position: relative;
}
.dropdownmenuServizi,
.matrimoniParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.matrimoniParent {
  width: 653px;
  justify-content: space-between;
}
.dropdownmenuServizi {
  position: relative;
  background-color: var(--white);
  justify-content: flex-start;
  padding: var(--padding-11xl);
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: left;
  font-size: var(--link-size);
  color: var(--black);
  font-family: var(--h3);
}
