.seiAncoraIndeciso {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-weight: 700;
  white-space: pre-wrap;
}
.buttonParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-11xl);
  cursor: pointer;
}
.contactFocus,
.seiAncoraIndecisoSuCosaFaParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.seiAncoraIndecisoSuCosaFaParent {
  align-items: center;
  justify-content: center;
  gap: var(--gap-31xl);
}
.contactFocus {
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-156xl) var(--padding-111xl);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/Users/lorenzogoretti/Desktop/LorenzoGorettiDJ-2/public/contact-focus.png) ;
  text-align: center;
  font-size: var(--text-size);
  color: var(--white);
  font-family: var(--h3);
}
@media screen and (max-width: 1200px) {
  .buttonParent {
    flex-direction: row;
  }
}
@media screen and (max-width: 960px) {
  .buttonParent {
    flex-direction: column;
  }
}
@media screen and (max-width: 500px) {
  .seiAncoraIndeciso {
    font-size: var(--font-size-xl);
  }
  .contactFocus {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}
