.cosaDeviSapere {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
}
.ilMatrimonioSi {
  color: var(--black);
}
.unoDeiGiorni {
  color: var(--primary);
}
.ilMatrimonioSiPuDefinire {
  display: block;
}
.ilMatrimonioSiContainer {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--text-size);
  white-space: pre-wrap;
  text-align: left;
  color: var(--black);
}
.cosaDeviSapereParent {
  align-self: stretch;
  gap: var(--gap-31xl);
}
.cosaDeviSapereParent,
.focusMatrimoniQuasiFinal,
.toKnow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.toKnow {
  align-self: stretch;
  overflow: hidden;
  padding: var(--padding-65xl) var(--padding-111xl);
}
.focusMatrimoniQuasiFinal {
  width: 100%;
  position: relative;
  background-color: var(--white);
  text-align: center;
  font-size: var(--h2-bold-size);
  color: var(--primary);
  font-family: var(--h3);
}
@media screen and (max-width: 500px) {
  .cosaDeviSapere {
    font-size: var(--font-size-31xl);
  }
  .ilMatrimonioSiContainer {
    font-size: var(--font-size-xl);
  }
  .toKnow {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}
