.button {
  position: relative;
  font-size: var(--link-size);
  font-family: var(--h3);
  color: var(--white);
  text-align: center;
}
.property1default {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xl) var(--padding-6xl);
  background-color: var(--primary);
  border-radius: var(--br-3xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.property1default:hover {
  background: linear-gradient(
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #cea350;
}
@media screen and (max-width: 500px) {
  .button {
    font-size: var(--font-size-xl);
  }
}
