.frameChild {
  width: 363px;
  position: relative;
  background-color: var(--primary);
  height: 2px;
}
.laMiaStoria,
.unaPassioneDiventata {
  margin: 0;
  align-self: stretch;
  position: relative;
}
.laMiaStoria {
  height: 124px;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
}
.unaPassioneDiventata {
  font-size: var(--subtitle-size);
}
.rectangleParent {
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  gap: var(--gap-31xl);
  z-index: 1;
}
.links,
.logo,
.menuLinks,
.rectangleParent {
  display: flex;
  align-items: center;
}
.logo {
  width: 117px;
  position: relative;
  justify-content: center;
  height: 52px;
  flex-shrink: 0;
}
.links,
.menuLinks {
  flex-direction: row;
}
.links {
  justify-content: center;
  gap: var(--gap-2xl);
}
.menuLinks {
  width: 393px;
  justify-content: space-between;
}
.vectorIcon {
  position: absolute;
  height: 55%;
  width: 80%;
  top: 20%;
  right: 10%;
  bottom: 25%;
  left: 10%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.lineHorizontal3Undefined {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: 0 !important;
  top: 19px;
  left: 76px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  z-index: 1;
}
.buttonParent,
.nav {
  flex-direction: row;
  align-items: center;
}
.buttonParent {
  width: 192px;
  display: flex;
  justify-content: center;
  position: relative;
}
.nav {
  width: 1296px;
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  justify-content: space-between;
  z-index: 2;
  font-size: var(--link-size);
}
.contentHero,
.heroChiSono {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.contentHero {
  align-items: center;
  position: relative;
  gap: var(--gap-sm);
}
.heroChiSono {
  background-color: var(--color-gray-200);
  height: 600px;
  overflow: hidden;
  flex-shrink: 0;
  align-items: flex-start;
  text-align: center;
  font-size: var(--h1-size);
  color: var(--white);
  font-family: var(--h3);
}
@media screen and (max-width: 960px) {
  .links {
    display: none;
  }
  .lineHorizontal3Undefined {
    display: flex;
  }
  .buttonParent {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .laMiaStoria {
    font-size: var(--font-size-51xl);
  }
  .unaPassioneDiventata {
    font-size: var(--font-size-xl);
  }
  .rectangleParent {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
  .heroChiSono {
    height: 500px;
  }
}
