.vuoiSaperneDi {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--h2-size);
  font-weight: 400;
  font-family: inherit;
  text-align: center;
}

.desktopChild {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 392px;
  object-fit: cover;
  transition: transform 0.3s;
}

.desktopChild:hover {
  transform: scale(1.1);
}

.span {
  display: block;
}

.avevoPocoPiDi14AnniQuan {
  display: block;
  color: var(--black);
}

.avevoPocoPiContainer {
  margin: 0;
  flex: 1;
  position: relative;
}

.desktop {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xl);
}

.desktopInner {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 392px;
  object-fit: cover;
  z-index: 0;
}

.inizioAGirareContainer {
  margin: 0;
  flex: 1;
  position: relative;
  z-index: 1;
}

.ellipseDiv {
  width: 83.3px;
  position: absolute;
  margin: 0 !important;
  top: 155px;
  left: calc(50% - 41.7px);
  border-radius: 50%;
  background-color: var(--primary);
  height: 83px;
  display: none;
  z-index: 2;
}

.desktop2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--gap-5xl);
}

.oggiAttualmenteCollaboroContainer {
  margin: 0;
  flex: 1;
  position: relative;
  white-space: pre-wrap;
}

.contentTimelineDesktop,
.ctaFinale,
.oggiDesktop,
.timeline {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
}

.oggiDesktop {
  flex-direction: row;
  gap: var(--gap-3xs);
}

.contentTimelineDesktop,
.ctaFinale,
.timeline {
  flex-direction: column;
}

.contentTimelineDesktop,
.timeline {
  align-items: flex-start;
  justify-content: flex-start;
}

.contentTimelineDesktop {
  gap: var(--gap-81xl);
}

.timeline {
  background-color: var(--white);
  overflow: hidden;
  padding: 0 var(--padding-111xl) var(--padding-131xl);
  text-align: left;
  font-size: var(--text-size);
  color: var(--primary);
  font-family: var(--h3);
}

.ctaCanvas {
  background-color: #cea350;
  text-decoration: none;
  border-radius: var(--br-3xs);
  border: 4px solid var(--primary);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xl) var(--padding-6xl);
  text-align: center;
  font-size: var(--link-size);
  color: var(--white);
  font-family: var(--h3);
}

@media screen and (max-width: 960px) {

  .avevoPocoPiContainer,
  .desktopChild {
    flex: unset;
    align-self: stretch;
  }

  .desktop {
    flex-direction: column;
  }

  .desktopInner,
  .inizioAGirareContainer {
    flex: unset;
    align-self: stretch;
  }

  .desktop2 {
    flex-direction: column;
  }

  .oggiAttualmenteCollaboroContainer {
    flex: unset;
    align-self: stretch;
  }

  .oggiDesktop {
    flex-direction: column;
  }

  .contentTimelineDesktop,
  .timeline {
    display: flex;
  }
  .ctaCanvas{
    font-size: var(--font-size-xl);
  }
    
    
}

@media screen and (max-width: 500px) {
  .vuoiSaperneDi {
    font-size: var(--font-size-31xl);
  }

  .avevoPocoPiContainer,
  .inizioAGirareContainer,
  .oggiAttualmenteCollaboroContainer {
    font-size: var(--font-size-xl);
  }

  .timeline {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}

.containerImg {
  overflow: hidden;
}