.imgMatrimoniIcon {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: 480px;
  object-fit: cover;
  transition: transform 0.3s;
}
.imgMatrimoniIcon:hover{
  transform: scale(1.1);
}
.matrimoni {
  margin: 0;
  width: 618px;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  height: 44px;
  flex-shrink: 0;
}
.contentMatrimoni {
  flex: 1;
  position: relative;
  height: 480px;
  overflow: hidden;
}
.content18esimi,
.primaRiga {
  display: flex;
  align-items: center;
}
.content18esimi {
  position: absolute;
  width: 100%;
  top: 164px;
  right: 0;
  left: 0;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.primaRiga {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-xl);
  text-align: center;
  font-size: var(--h3-size);
  color: var(--white);
  font-family: var(--h3);
}
@media screen and (max-width: 960px) {
  .contentMatrimoni {
    flex: unset;
    align-self: stretch;
  }
  .primaRiga {
    flex-direction: column;
  }
}
@media screen and (max-width: 500px) {
  .matrimoni {
    font-size: var(--font-size-16xl);
  }
}
